import React, { useState, useRef } from 'react';
import styles from './Sidebar.module.css';
import Hamburger from 'hamburger-react';
import { NavLink } from 'react-router-dom';


const Sidebar = () => {
    const [isOpen, setOpen] = useState(false);
    const sidebarRef = useRef(null);
    const hamburgerRef = useRef(null);


    const toggleSidebar = () => {
        setOpen(!isOpen);
    };

    return (
        <>
            <header className={styles["menu-button-container"]}>
                <div className={styles['menu-button']} ref={hamburgerRef}>
                    <Hamburger
                    size={40}
                    toggled={isOpen}
                    toggle={toggleSidebar}
                    color={isOpen ? '#000000' : '#000000'}
                    direction="right"
                    distance="sm"
                    />
                </div>
            </header>
            
            <div className={`${styles.sidebar} ${isOpen ? styles.open : ''}`} ref={sidebarRef}>
                
                <nav className={styles.menu}>
                    <ul className={styles["nav-links"]}>
                        <li><NavLink to ="/" className={styles["nav-link"]}>Home</NavLink></li>
                        <li><NavLink to = "/services" className={styles["nav-link"]}>Services</NavLink></li>
                        <li><NavLink to = "/about" className={styles["nav-link"]}>About GMMCARE</NavLink></li>
                        <li><NavLink to = "/contact" className={styles["nav-link"]}>Contact Us</NavLink></li>
                    </ul>
                </nav>
            </div>
        </>
    )
}
export default Sidebar;
