import React from 'react';
import styles from './Home.module.css';
import { Link } from 'react-router-dom';
import InfoHeader from '../components/InfoHeader.js';
import Header from '../components/Header.js';
import Carousel from '../components/Carousel.js';
import Footer from '../components/Footer.js';
import fluShotImage from '../images/vaccine.png';
import drugTestImage from '../images/drug-test.png';
import covidImage from '../images/swab.png';
import seniorImage from '../images/senior-couple.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons'



function Home() {
    return (
        <>
            <InfoHeader/>
            <Header/>
            <section className={styles["hero-container"]}>
                <Carousel/>
                {/* <div className={styles["slanted-rectangle-overlay"]}></div> */}
            </section>
            

            <section className={styles["services-container"]}>
                <h2 className={styles["services-section-title"]}>
                    Revolutionizing Healthcare on Wheels
                </h2>
                <div className={styles["services-section-text"]}>

                    <p className={["services-section-text-paragraph-one"]}>
                    We offer a wide range of health services through our 
                    mobile medical lab, with convenient access to 
                    essential care for everyone.
                    </p>

                    <p className={["services-section-text-paragraph-two"]}>
                    Our goal is to provide a seamless healthcare 
                    experience, bringing personalized and professional 
                    medical attention directly to the community, catering 
                    to both preventative and immediate health needs.
                    </p>

                </div>

                <div className={styles["services-section-cards-container"]}>
                    <div className={styles["services-section-card"]}>
                        <img className={styles["services-card-image"]} src={fluShotImage} alt="flu shot"></img>
                        <h3 className={styles["services-card-title"]}>Flu Shots</h3>
                        <p className={styles["services-card-text"]}>
                        Safeguard your health with our
                        convenient flu vaccination service
                        </p>
                    </div>

                    <div className={styles["services-section-card"]}>
                        <img className={styles["services-card-image"]} src={covidImage} alt="covid test"></img>
                        <h3 className={styles["services-card-title"]}>COVID Testing</h3>
                        <p className={styles["services-card-text"]}>
                        Quick and reliable testing 
                        at your convenience 
                        </p>
                    </div>

                    <div className={styles["services-section-card"]}>
                        <img className={styles["services-card-image"]} src={drugTestImage} alt="flu shot"></img>
                        <h3 className={styles["services-card-title"]}>Drug Screening</h3>
                        <p className={styles["services-card-text"]}>
                        Discreet on-site drug testing 
                        ensuring privacy and accuracy
                        </p>
                    </div>
                </div>

                <div className={styles["btn-container"]}>
                        <Link to="/services" className={styles["learn-btn"]}>Learn More</Link>
                </div>
                
            </section>

            <section className={styles["medicare-hmo-container"]}>
                <h2 className={styles["medicare-hmo-title"]}> Get Assistance Switching to Original Medicare</h2>
                <div className={styles["medicare-hmo-text"]}>
                        <p className={styles["medicare-hmo-text-paragraph"]}>
                        Are you considering removing your HMO and 
                        reverting back to Original Medicare? Many find that
                        limited provider networks, the need for referrals to 
                        see specialists, and annual changes in plan
                        benefits can be restrictive. 
                        </p>
                </div>

                <div className={styles["medicare-hmo-content"]}>
                        <div className={styles["column-one"]}>
                            <h2 className={styles["medicare-hmo-title-two"]}>Advantages of Original Medicare</h2>

                            <ul className={styles["list"]}>
                                <li><FontAwesomeIcon icon ={faCheck} className={styles["check-icon"]}/>Nationwide Coverage</li>
                                <li><FontAwesomeIcon icon ={faCheck} className={styles["check-icon"]}/>No Referrals Required</li>
                                <li><FontAwesomeIcon icon ={faCheck} className={styles["check-icon"]}/>Option to Purchase Medigap</li>
                                <li><FontAwesomeIcon icon ={faCheck} className={styles["check-icon"]}/>Standardized Benefits</li>
                                <li><FontAwesomeIcon icon ={faCheck} className={styles["check-icon"]}/>No Network Restrictions</li>
                            </ul>
                        </div>
                        <div className={styles["column-two"]}>
                            <img className={styles["medicare-hmo-image"]} src={seniorImage} alt="senior couple speaking with doctor"></img>
                        </div>    
                </div>

                <div className={styles["medicare-btn-container"]}>
                            <Link to="#" className={styles["medicare-btn"]}>Check Elgibility</Link>
                </div>
                
            </section>

            <section className={styles["about-section-container"]}>
                <h2 className={styles["about-section-title"]}>We Strive to Drive Wellness</h2>
                <div className={styles["about-section-text"]}>
                    <p className={styles["about-section-text-paragraph"]}>
                    At GMMCare, our mission is to deliver essential healthcare services with a commitment to accessibility.
                    We strive to provide compassionate care through our mobile medical lab, ensuring every community
                    member has the opportunity to receive high quality medical attention and healthcare services. Fostering
                    the health and well-being of our community is our top priority, guaranteeing that our services — from 
                    preventative screenings to comprehensive tests — are accessible to everyone. Our dedication lies in
                    bridging the gaps in healthcare access, one step at a time.
                    </p>

    
                </div>

                <div className={styles["about-btn-container"]}>
                    <Link to="/about" className={styles["about-btn"]}>Learn more</Link>
                </div>

            </section>

            <Footer/>
        </>

    )
}

export default Home;
