import React from 'react';
import styles from './Services.module.css';
import InfoHeader from '../components/InfoHeader.js';
import Header from '../components/Header.js';
import Footer from '../components/Footer.js';

function Services() {
    return (
        <>
        <InfoHeader/>
        <Header/>
        
        <div className={styles["services-container"]}>
            <h1 className={styles["services-title"]}>Our Services</h1>
            <p className={styles["services-text"]}>
            At GMMCARE, we provide a unique approach to healthcare through our stationary mobile medical lab, 
            conveniently located to serve you better. Equipped with the latest in medical technology, our lab offers 
            a wide range of healthcare services in one accessible location. We are committed to offering 
            personalized care, combining expertise with compassion and excellence to meet your healthcare needs 
            effectively.
            </p>

            <h2 className={styles["services-subtitle"]}>Diagnostic Testing</h2>
            <ul className={styles["services-list"]}>
                <li>Blood tests</li>
                <li>Drug Tests</li>
                <li>COVID-19 Testing</li>
                <li>Allergy Testing</li>
            </ul>

            <h2 className={styles["services-subtitle"]}>Vaccinations</h2>
            <ul className={styles["services-list"]}>
                <li>Flu Shots</li>
                <li>COVID-19-Vaccinations</li>
            </ul>

            <h2 className={styles["services-subtitle"]}>Wellness Services</h2>
            <ul className={styles["services-list"]}>
                <li>Physical Examinations</li>
                <li>Mental Health Screenings</li>
            </ul>

            <p className={styles["services-text"]}>Ready to schedule your visit or want more information about our services? Contact us at  <a href="tel:+15622250263" className={styles["phone-number"]}>(562)-225-0263</a>.</p>

        </div>
        <Footer/>
        </>
    )
}

export default Services;