import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from './Carousel.module.css'
import slideImage1 from '../images/team-cropped.jpg';
import slideImage2 from '../images/chronic-pain.jpg';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';


    const CustomPrevArrow = (props) => (
        <div className={styles["custom-prev-arrow"]} onClick={props.onClick}>
            <FaChevronLeft size={40}/>
        </div>
    );

    const CustomNextArrow = (props) => (
        <div className={styles["custom-next-arrow"]} onClick={props.onClick}>
            <FaChevronRight size={40}/> 
        </div>
    );

    function Carousel() {
        var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        mobileFirst: true,
        initialSlide: 0,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />,
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false,
                    swipe: false
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false
                }
            }
        ]
        };

    return (
        <>
            <div className={styles["carousel-wrapper"]}>
                <div className={styles["slider-container"]}>
                    <Slider {...settings}>
                        <div className={styles["slide-content"]}>
                            <div className={styles["slide-image-container"]}>
                                <img src={slideImage1} className={styles["slide-image"]} alt="3 person team of doctors" />
                            </div>

                            <div className={styles["slide-text"]}>
                                <p className={styles["paragraph-one"]}>
                                    GMMCARE is commited to enriching community heath, providing
                                    healthcare services through our mobile lab, ensuring every 
                                    individual receives the care and support they need.
                                </p>

                                <p className={styles["paragraph-two"]}>
                                    Discover your path to better health today!
                                </p>

                                <p className={styles["paragraph-link"]}>
                                    <a href="https://google.com" className={styles["link"]}>Click here</a> here to learn more
                                </p>
                            </div>
                        </div>

                        <div className={styles["slide-content"]}>
                            <img src={slideImage2} className={styles["slide-image"]} alt="doctor with chronic pain patient" />
                        </div>
                    </Slider>
                    <div className={styles["slanted-rectangle-overlay"]}></div>
                </div>
            </div>
        </>      
    );
}
export default Carousel;