import React from 'react';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import styles from './InfoHeader.module.css';
import arrowIcon from '../images/down.png';
import phoneIcon from '../images/phone-call.png';
import mailIcon from '../images/mail.png'

const InfoHeader = () => {
    const { i18n: i18next } = useTranslation();

    const handleLanguageChange = (event) => {
        i18next.changeLanguage(event.target.value);
    };

    return (
        <div className={styles["info-container"]}>
            <div className={styles["translation"]}>
                <select onChange={handleLanguageChange} className={styles["language-selector"]}>
                    <option value="en">English</option>
                    <option value="es">Spanish</option>
                </select>
            </div>

            <div className={styles["contact-info"]}>
                <div className={styles["phone"]}>
                    <img className={styles["phone-icon"]}  src={phoneIcon} alt="phone icon"></img>
                    <a href="tel:+15622250263">  
                        <p className={styles["phone-number"]}>(562)-225-0263</p>
                    </a>
                </div>

                <div className={styles["email"]}>
                    <img className={styles["email-icon"]} src={mailIcon}></img>
                <a href="mailto:contact@gmmcare.com" target="_blank" rel="noopener noreferrer">
                    <p className={styles["email-address"]}>Email</p>
                </a>
                </div>

                
            </div>
            
        </div>
    )
}

export default InfoHeader;