import React from 'react';
import styles from './Contact.module.css';
import InfoHeader from '../components/InfoHeader.js';
import Header from '../components/Header.js';
import ContactForm from '../components/ContactForm.js';

function Contact() {
    return (
        <>
        <InfoHeader/>
        <Header/>

        <div className={styles["contact-container"]}>
            <h1 className={styles["contact-title"]}>Contact Us</h1>
            <p className={styles["contact-text"]}>For immediate assistance, please call our office.</p>
            <p className={styles["contact-text"]}>Fill out the form below if you have any questions, and we will get
            back to you as soon as possible.</p>
            <div className={styles["form-container"]}>
                <ContactForm />
            </div>

        </div>
        </>
    )
}
export default Contact;