import React from 'react';
import styles from './ContactForm.module.css';

function ContactForm() {
    

    return (
        <div className={styles["form-container"]}>
                <form
                    className={styles["form-content"]}
                    action="https://formspree.io/f/xyzgkgwj"
                    method="POST"
                >
                    <div className={styles["name-field"]}>
                        <div className={styles["fname"]}>
                            <label htmlFor="fname">First Name</label>
                            <input type="text" id="fname" name="fname" required />
                        </div>
                        <div className={styles["lname"]}>
                            <label htmlFor="lname">Last Name</label>
                            <input type="text" id="lname" name="lname" required />
                        </div>
                    </div>

                    <div className={styles["phone-number"]}>
                        <label htmlFor="phone">Phone Number</label>
                        <input type="tel" id="phone" name="phone" required />
                    </div>

                    <div className={styles["email-field"]}>
                        <label htmlFor="email">Email Address</label>
                        <input type="email" id="email" name="email" required />
                    </div>

                    <div className={styles["subject-field"]}>
                        <label htmlFor="message-box">How can we help you?</label>
                        <textarea id="message-box" name="message-box" rows="8" className={styles["text-area"]} required />
                    </div>

                    <div className={styles["button-container"]}>
                        <button
                            type="submit"
                            className={styles["submitButton"]}
                        >
                            Submit
                        </button>
                    </div>
                </form>
        </div>
    );
}

export default ContactForm;

