import React from 'react';
import styles from './About.module.css';
import InfoHeader from '../components/InfoHeader.js';
import Header from '../components/Header.js';
import Footer from '../components/Footer.js';

function About() {
    return (
        <>
            <InfoHeader/>
            <Header/>

            <div className={styles["about-container"]}>
                <h1 className={styles["about-title"]}>About GMMCARE</h1>
                <p className={styles["about-text-paragraph-one"]}>
                GMMCARE is a community-focused mobile medical lab, offering essential health services like blood
                tests and drug testing with convenience at heart. We are available at various locations, we bring 
                healthcare closer to you, eliminating the need for long travels to access quality medical testing.
                </p>

                <p className={styles["about-text-paragraph-two"]}>
                We serve as a bridge to ongoing medical support, linking you to a comprehensive network of physicians
                for specialized care, including pain management and a range of clinical treatments. We’re dedicated to 
                ensuring that you receive prompt, accurate, and compassionate care tailored to your individual health needs. Our mission is to simplify your journey to health by providing 
                both essential medical testing and a seamless connection to specialist care.  Whether you need a routine blood check-up, comprehensive metabolic panel, or specialized drug screening, 
                our skilled medical staff are prepared to assist you with professional expertise.
                </p>

                <p className={styles["about-text-paragraph-three"]}>
                At GMMCARE, we understand the importance of accessing healthcare, which is why our services are designed to be convenient and efficient. 
                By situating our facilities in key community locations, we make it easier for you to access the services you need. We are more than just a testing service; 
                we are partners in health. Our team works closely with local healthcare providers and specialists to create a coordinated care pathway that supports your health goals. 
                </p>
            </div>
            
            <Footer/>
            
        </>
    )
}
export default About;