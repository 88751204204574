import React from 'react';
import styles from './Footer.module.css';
import facebookIcon from '../images/facebook.png';
import linkedInIcon from '../images/linkedin.png';
import yelpIcon from '../images/yelp.png';
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <>
            <div className={styles["footer-container"]}>
                <div className={styles["footer-content"]}>
                    <div className={styles["footer-column-one"]}>
                        <h4 className={styles["footer-title"]}>GMMCARE</h4>
                        <p className={styles["footer-address-name"]}>LA Wellness Station</p>
                        <p className={styles["footer-address-line1"]}>5416 Long Beach Avenue</p>
                        <p className={styles["footer-address-city"]}>Los Angeles, CA 90058</p>
                    </div>
                    
                    <div className={styles["footer-column-two"]}>
                        <p className={styles["footer-connect"]}>Connect with us:</p>
                        <div className={styles["footer-icons"]}>
                            <a href="#">
                                <img className={styles["facebook-icon"]} src={facebookIcon} alt="facebook"></img>
                            </a>
                            
                            <a href="#">
                                <img className={styles["linkedin-icon"]} src={linkedInIcon} alt="linkedIn"></img>
                            </a>
                            
                            <a href="#">
                                <img className={styles["yelp-icon"]} src={yelpIcon} alt="yelp"></img>
                            </a>
                        
                        </div>
                    </div>

                    <div className={styles["footer-column-three"]}>
                        <div className={styles["footer-btn-container"]}>
                            <Link to="/contact" className={styles["contact-btn"]}>Contact Us</Link>
                        </div>
                    </div>
                 </div>  
            </div>
                <div className={styles["footer-copyright"]}>
                    <p className={styles["footer-copyright-text"]}>© 2024 GMMCARE. All rights reserved.</p>
                </div>
        </>
    )
}

export default Footer;